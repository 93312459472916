import logo from './logo.svg';
import './App.css';
import General from './general.js';

function App() {
  document.title = "Anran XU";
  return (
    <div className="App">
      <General />
    </div>
  );
}

export default App;
