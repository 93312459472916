import React, { Component } from 'react';
import { Box, Container, Typography } from '@mui/material';
import './about.css';
import { Scrollbars } from 'rc-scrollbars';
// News data array
const newsData = [
    {
        date: "2024.10",
        items: [
            'My IMWUT (Ubicomp \'24) paper was selected as the Distinguished Paper Award (8 out of 205 published papers)!',
            'I am going to present my IMWUT (Ubicomp \'24) paper "DIPA2: An Image Dataset with Cross-cultural Privacy Perception Annotations". I am looking forward to discussing the privacy perception in cross-cultural backgrounds with you in Melbourne, Australia in October!'
        ]
    },
    {
        date: "2024.8",
        items: [
            'I visited CyLab at Carnegie Mellon University in Pittsburgh and presented my research on image privacy protection at SOUPS in Philadelphia.'
        ]
    },
    {
        date: "2024.6",
        items: [
            'My poster submitted to SOUPS 2024 was accepted. I am looking forward to chatting about any research ideas about usable security in Philadelphia in August!'
        ]
    },
    {
        date: "2024.5",
        items: [
            "I attended CHI 2024 and presented my paper \"Examining Human Perception of Generative Content Replacement in Image Privacy Protection\" in Hawaii.",
        ]
    },
    {
        date: "2024.4",
        items: [
            "Google granted the support to me to attend CHI 2024."
        ]
    },
    {
        date: "2024.2",
        items: [
            "My CHI 2024 paper \"Examining Human Perception of Generative Content Replacement in Image Privacy Protection\" was accepted. This research developed a novel image privacy protection method called \"generative content replacement (GCR)\" and investigated the human perception of the method."
        ]
    },
    {
        date: "2023.11",
        items: [
            "My IMWUT (Ubicomp '24) paper \"DIPA2: An Image Dataset with Cross-cultural Privacy Perception Annotations\" was accepted. This paper further extends our IUI '23 paper with more human-centered metrics on privacy and deeply investigates the influence of human factors on privacy perception."
        ]
    },
    {
        date: "2023.10",
        items: [
            "I delievered talks about image privacy protection at University of Oulu and University of Lapland in Finland."
        ]
    },
    {
        date: "2023.3",
        items: [
            "I published and presented my short paper \"DIPA: An Image Dataset with Cross-cultural Privacy Concern Annotations\" at IUI '23 in Sydney.",
            "I became a Microsoft D-CORE Joint Research Faculty Member and was supported with $10,000 in research funding!",
            "SIGCHI granted the support to me to attend IUI 2023 through Gary Marsden Travel Awards."
        ]
    },
	{
        date: "2022.10",
        items: [
            "I started my Ph.D. at The University of Tokyo!"
        ]
    }
];

class About extends Component {
    
	renderNews = () => (
        newsData.map((newsEntry, index) => (
            <Box key={index} sx={{ mb: 2 }}>
                <Typography variant="h6">{newsEntry.date}:</Typography>
                {newsEntry.items.map((item, subIndex) => (
                    <Typography key={subIndex} paragraph>{item}</Typography>
                ))}
            </Box>
        ))
    );

    render (){
		return(
			<div className='aboutMain'>
				{this.props.show ? (
				<Container sx={window.innerWidth > this.props.xlBoundary ? { mt: 4, mb: 4, ml: 4, mr: 4} : {mt: 4}}>
					<Box>
						<Typography variant="h4" gutterBottom>About Me</Typography>
						<Typography paragraph sx={{ fontSize: '1.1rem' }}>
							I am a Ph.D. student in the Interactive Intelligent Systems Laboratory (<a href="https://iis-lab.org/" target="_blank" rel="noopener noreferrer">https://iis-lab.org/</a>) at The University of Tokyo, supervised by Prof. Koji Yatani (<a href="https://iis-lab.org/member/koji-yatani/" target="_blank" rel="noopener noreferrer">https://iis-lab.org/member/koji-yatani/</a>). I received my B.S. degree at Peking University, supervised by Prof. Huijing Zhao in the Intelligent Vehicle and Mobile Robot Lab (<a href="http://www.poss.pku.edu.cn/index.html" target="_blank" rel="noopener noreferrer">http://www.poss.pku.edu.cn/index.html</a>).
						</Typography>
						<Typography paragraph sx={{ fontSize: '1.1rem' }}>
							My current research is on promoting people's cyber hygiene when they share photos by providing novel image protection methods, e.g., <strong>Generative Content Replacement</strong> (GCR) (<a href="https://dl.acm.org/doi/10.1145/3613904.3642103" target="_blank" rel="noopener noreferrer">https://dl.acm.org/doi/10.1145/3613904.3642103</a>). I am also working on a publicly-available dataset called <strong>DIPA</strong> for privacy concerns on photos (<a href="https://anranxu.github.io/DIPA2_VIS/" target="_blank" rel="noopener noreferrer">https://anranxu.github.io/DIPA2_VIS/</a>).
						</Typography>
						<Typography paragraph sx={{ fontSize: '1.1rem' }}>
							My research interests include <strong>Human-computer Interaction</strong> (HCI), <strong>Usable Security</strong>, <strong>Computer Vision</strong> (CV), and <strong>Machine Learning</strong> (ML). More specifically, I am passionate about combining these technologies with HCI theories to alleviate the isolation of non-expert users.
						</Typography>
						<Typography paragraph sx={{ fontSize: '1.1rem' }}>
							Please feel free to contact me (<a href="mailto:anranxu98@gmail.com" target="_blank" rel="noopener noreferrer">anranxu98@gmail.com</a>) if you would like to work with me.
						</Typography>
					</Box>
					<Box>
						<Typography variant="h4" gutterBottom>News</Typography>
                        {   window.innerWidth > this.props.xlBoundary ?
                            <Scrollbars style={{ height: document.documentElement.clientHeight * 2/3 }}>
                                {this.renderNews(newsData)}
                            </Scrollbars>:
                            this.renderNews(newsData)
                        }
					</Box>
				</Container>
				):null}
				</div>
		);
	}
}

export default About;
